import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
const REACT_APP_NODE_API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;

const SQLQueryExecutor = () => {
  const [query, setQuery] = useState("");
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);

  const executeQuery = async () => {
    setLoading(true);
    setResult("Loading...");
    try {
      const response = await fetch(
        `${REACT_APP_NODE_API_BASE_URL}/execute-query`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query,
          }),
        }
      );
      const resultText = await response.text();
      setResult(resultText);
    } catch (error) {
      setResult("Error executing query. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const executeQueryDownload = async () => {
    setLoading(true);
    setResult("Loading...");
    try {
      const response = await fetch(
        `${REACT_APP_NODE_API_BASE_URL}/download-query-data`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data.");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "query-data.csv"; // Change the filename as needed
      document.body.appendChild(a);
      a.click();
      a.remove();
      setResult("File downloaded successfully.");
    } catch (error) {
      setResult("Error executing query. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const DynamicTable = ({ htmlContent }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };

  return (
    <div className="min-vh-100 d-flex flex-column align-items-center bg-light">
      {/* Main Content */}
      <div className="container py-5">
        <div
          className="card shadow-sm p-4 mx-auto"
          style={{ maxWidth: "800px" }}
        >
          <h2 className="text-center text-primary mb-4">
            Execute Your SQL Query
          </h2>

          <textarea
            className="form-control mb-3"
            rows="6"
            placeholder="Enter your SQL query here"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          ></textarea>

          <button
            className="btn btn-primary w-100 mb-3"
            onClick={executeQuery}
            disabled={loading}
          >
            {loading ? "Executing..." : "Execute Query"}
          </button>

          <button
            className="btn btn-primary w-100 mb-3"
            onClick={executeQueryDownload}
          >
            Direct Download CSV
          </button>

          <div
            id="result"
            className="border rounded p-3 bg-light"
            style={{ overflow: "auto", maxHeight: "300px" }}
          >
            <DynamicTable htmlContent={result} />
          </div>
        </div>

        {/* Suggestion Block */}
        <div className="card mt-4 p-4 shadow-sm">
        <h4 className="text-secondary mb-3">
            Get Users Details - Using ID, Mobile
          </h4>
          <pre className="bg-dark text-white p-3 rounded">
            SELECT * FROM `users` WHERE whatsapp = 8866853894;
            <br />
            <br />
            SELECT * FROM `users` WHERE id = 10;
          </pre>
          
          <h4 className="text-secondary mb-3">AI Analytics:</h4>
          <pre className="bg-dark text-white p-3 rounded">
            SELECT u.id, u.name, u.whatsapp, u.role, 13 - MAX(ud.standard_id) AS
            standard, ai.feature_id, ai.created_at FROM ai_analysis ai INNER
            JOIN users u ON u.id = ai.user_id LEFT JOIN user_defaults ud ON
            ud.user_id = u.id WHERE ai.created_at >= NOW() - INTERVAL 7 DAY
            GROUP BY u.id, u.slug, u.name, u.whatsapp, u.role, u.created_at,
            ai.feature_id, ai.created_at;
          </pre>
          <h4 className="text-secondary mb-3">Users Analytics:</h4>
          <pre className="bg-dark text-white p-3 rounded">
            SELECT u.id, u.slug, u.name, u.whatsapp, u.role, 13 -
            MAX(ud.standard_id) AS standards, u.created_at AS signup_date,
            ua.session_id, ua.page_visited, ua.time_taken, ua.platform,
            ua.created_at AS session_created_at FROM user_analytics ua INNER
            JOIN users u ON u.id = ua.user_id LEFT JOIN user_defaults ud ON
            ud.user_id = u.id WHERE ua.created_at >= NOW() - INTERVAL 1 DAY
            GROUP BY u.id, u.slug, u.name, ua.session_id, ua.platform,
            ua.page_visited, ua.time_taken, ua.created_at
          </pre>
          <h4 className="text-secondary mb-3">Gulab Query:</h4>
          <pre className="bg-dark text-white p-3 rounded">
            SELECT DISTINCT u.id, u.name, u.whatsapp, u.created_at as
            signup_date, 13 - max_standards.max_standard_id as standard,
            g.count1 AS aajnu_gulab, g.count2 AS aajnu_dipak, g.count3 AS
            shala_gaurav, g.count4 AS samay_saarthi, g.count5 AS vidhya_sidhh,
            g.count6 AS ekam_kasoti_vijeta, g.count7 AS hajri_champion, g.count8
            AS helping_hero, g.count9 AS vidhya_bhamasha, g.download_date AS
            last_download_date, g.streak, MAX(gs.streak_date) AS streak_date
            FROM gulabdownloads g JOIN users u ON g.user_id = u.id LEFT JOIN (
            SELECT user_id, MAX(standard_id) AS max_standard_id FROM
            user_defaults GROUP BY user_id ) AS max_standards ON u.id =
            max_standards.user_id LEFT JOIN gulab_user_streak gs ON gs.user_id =
            g.user_id WHERE g.download_date > '2024-11-15' GROUP BY u.id,
            u.name, u.whatsapp, u.created_at, g.count1, g.count2, g.count3,
            g.count4, g.count5, g.count6, g.count7, g.count8, g.count9,
            g.download_date;
          </pre>
          <h4 className="text-secondary mb-3">Get Contnet Created Users</h4>
          <pre className="bg-dark text-white p-3 rounded">
            <b>tests</b>
            <br />
            SELECT u.id, u.slug, u.name, u.whatsapp, u.role,u.created_at as
            signup_date, count(t.id) as total_test FROM tests t JOIN users u on
            u.id = t.user_id WHERE t.board_id = 1 AND t.medium_id = 1 AND
            t.standard_id = 8 AND t.is_deleted = 0 AND t.deleted_at IS NULL and
            t.created_at >= NOW() - INTERVAL 1 WEEK GROUP BY u.id;
            <br />
            <br />
            <b>videos</b>
            <br />
            SELECT u.id, u.slug, u.name, u.whatsapp, u.role,u.created_at as
            signup_date, count(t.id) as total_videos FROM videos t JOIN users u
            on u.id = t.user_id WHERE t.board_id = 1 AND t.medium_id = 1 AND
            t.standard_id = 8 AND t.is_deleted = 0 AND t.deleted_at IS NULL and
            t.created_at >= NOW() - INTERVAL 1 WEEK GROUP BY u.id;
            <br />
            <br />
            <b>materials</b>
            <br />
            SELECT u.id, u.slug, u.name, u.whatsapp, u.role,u.created_at as
            signup_date, count(t.id) as total_materials FROM materials t JOIN
            users u on u.id = t.user_id WHERE t.board_id = 1 AND t.medium_id = 1
            AND t.standard_id = 8 AND t.is_deleted = 0 AND t.deleted_at IS NULL
            and t.created_at >= NOW() - INTERVAL 1 WEEK GROUP BY u.id;
            <br />
            <br />
            <b>exams</b>
            <br />
            SELECT u.id, u.slug, u.name, u.whatsapp, u.role,u.created_at as
            signup_date, count(t.id) as total_exams FROM exams t JOIN users u on
            u.id = t.user_id WHERE t.board_id = 1 AND t.medium_id = 1 AND
            t.standard_id = 8 AND t.is_deleted = 0 AND t.deleted_at IS NULL and
            t.created_at >= NOW() - INTERVAL 1 WEEK GROUP BY u.id;
          </pre>

          <h4 className="text-secondary mb-3">Get Contnet Attempted Users</h4>
          <pre className="bg-dark text-white p-3 rounded">
            <b>Material Attempt:</b>
            <br />
            SELECT u.id, u.slug, u.name, u.whatsapp, u.role, 13 -
            (ud.standard_id) AS standard, COUNT(ts.material_id ) AS
            total_attempt FROM material_view_histories ts LEFT JOIN
            user_defaults ud on ud.user_id = ts.user_id LEFT JOIN users u ON
            u.id = ts.user_id WHERE ts.created_at >= NOW() - INTERVAL 1 WEEK
            GROUP BY u.id, ud.standard_id;
            <br />
            <br />
            <b>Quiz Attempt:</b>
            <br />
            SELECT u.id, u.slug, u.name, u.whatsapp, u.role, 13 -
            (ud.standard_id) AS standard, COUNT(ts.test_id) AS total_attempt
            FROM test_scores ts LEFT JOIN user_defaults ud on ud.user_id =
            ts.user_id LEFT JOIN users u ON u.id = ts.user_id WHERE
            ts.created_at >= NOW() - INTERVAL 1 WEEK GROUP BY u.id,
            ud.standard_id;
            <br />
            <br />
            <b>Video Attempt:</b>
            <br />
            SELECT u.id, u.slug, u.name, u.whatsapp, u.role, 13 -
            (ud.standard_id) AS standard, COUNT(ts.video_id) AS total_attempt
            FROM video_views ts LEFT JOIN user_defaults ud on ud.user_id =
            ts.user_id LEFT JOIN users u ON u.id = ts.user_id WHERE
            ts.created_at >= NOW() - INTERVAL 1 WEEK GROUP BY u.id,
            ud.standard_id;
            <br />
            <br />
            <b>Exam Attempt:</b>
            <br />
            SELECT u.id, u.slug, u.name, u.whatsapp, u.role, 13 -
            (ud.standard_id) AS standard, COUNT(ts.exam_id) AS total_attempt
            FROM exam_scores ts LEFT JOIN user_defaults ud on ud.user_id =
            ts.user_id LEFT JOIN users u ON u.id = ts.user_id WHERE
            ts.created_at >= NOW() - INTERVAL 1 WEEK GROUP BY u.id,
            ud.standard_id;
          </pre>
        </div>
      </div>
    </div>
  );
};

export default SQLQueryExecutor;
