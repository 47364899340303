import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function SystemMessage() {
    const API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;
    const [elements, setelements] = useState([]);
    const [loading, setloading] = useState(true)
    const [editElement, setEditElement] = useState(false);
    const [systemPrompt, setSystemPrompt] = useState(null);
    const [promptMessage, setPromptMessage] = useState(null);
    const [systemPromptName, setSystemPromptName] = useState(null);
    const [selectedType, setselectedType] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const res = await axios.get(`${API_BASE_URL}/chapter-ai/v1/system-message`);
        setelements(res.data.systemMessages);
        setloading(false)
    };

    const addSystemMessage = async () => {
        if (!systemPromptName) {
            toast.error("System Prompt Name is mandatory", {
                position: "top-center",
            });
            return;
        }
        try {
            const addSystemMessage = {
                type: selectedType,
                system_prompt_name: systemPromptName,
                system_prompt: systemPrompt,
                prompt_message: promptMessage,
            };
            const addSystemMessageResponse = await axios.post(
                `${API_BASE_URL}/chapter-ai/v1/add/system-message`,
                addSystemMessage,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (addSystemMessageResponse.data) {
                toast.success(addSystemMessageResponse.data.message, {
                    position: "top-center",
                });
                fetchData();
                setEditElement(false);
                setSystemPromptName(null);
                setSystemPrompt(null);
                setPromptMessage(null);
            }
        } catch (error) {
            toast.error(error.response.data.error, {
                position: "top-center",
            });
            console.error("Error updating system message:", error);
        }
    };

    const deleteSystemMessage = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this system message?");
        if (!confirmDelete) {
            return;
        }
        try {
            const deleteSystemMessageResponse = await axios.delete(
                `${API_BASE_URL}/chapter-ai/v1/delete/system-message/${id}`,
            );
            toast.success(deleteSystemMessageResponse.data.message, {
                position: "top-center",
            });
            fetchData();
        } catch (error) {
            console.error("Error deleting system message:", error);
        }
    }
    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>List of Elements</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <p>Home</p>
                            </li>
                            <li className="breadcrumb-item">Element list</li>
                        </ol>
                    </nav>
                </div>

                <div className="card">
                    <div className="card-body">
                        <form className="row g-3" encType="multipart/form-data">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1">Type :</label>
                                <select
                                    className="form-control mt-1"
                                    value={selectedType}
                                    onChange={(e) => setselectedType(e.target.value)}
                                >
                                    <option selected value="">
                                        Select
                                    </option>
                                    <option value="1">Element</option>
                                    <option value="2">Suggested Task</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1">System Prompt Name: </label>
                                <input
                                    className="form-control"
                                    value={systemPromptName}
                                    onChange={(e) => setSystemPromptName(e.target.value)}
                                    required
                                    rows={1}
                                />
                            </div>

                            {/* <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1">System Message: </label>
                                <textarea
                                    className="form-control"
                                    value={systemPrompt}
                                    onChange={(e) => setSystemPrompt(e.target.value)}
                                    required
                                    rows={5}
                                />
                            </div> */}

                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1">Prompt Message: </label>
                                <textarea
                                    className="form-control"
                                    value={promptMessage}
                                    onChange={(e) => setPromptMessage(e.target.value)}
                                    required
                                    rows={5}
                                />
                            </div>
                            <div className="text-center">
                                <button
                                    type="button"
                                    className="btn btn-success mx-3"
                                    name="insert"
                                    onClick={addSystemMessage}
                                >
                                    Add Message
                                </button>
                            </div>
                        </form>
                        <hr className="my-4"></hr>
                        <div className="scrolltable" style={{ overflow: "auto" }}>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">System Prompt ID</th>
                                        <th scope="col">Element Name</th>
                                        <th scope="col">Created Time</th>
                                        <th scope="col">Action </th>
                                    </tr>
                                </thead>
                                {loading ? (
                                    <div class="spinner-border text-primary mt-3" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                ) : (
                                    <tbody>
                                        {elements.map((element, index) => (
                                            <tr key={`${index}-${element.system_prompt_id}`}>
                                                <td>{index + 1}</td>
                                                <td>#{element.system_prompt_id}</td>
                                                <td>{element.system_prompt_name}</td>
                                                <td>{element.created_at}</td>
                                                <td>
                                                    <Link to={`/edit-system-message/${element.system_prompt_id}`}>
                                                        <button className="btn btn-primary">
                                                            Edit
                                                        </button>
                                                    </Link>
                                                </td>
                                                <td>
                                                    <button className="btn btn-danger" onClick={() => deleteSystemMessage(element.system_prompt_id)}>Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default SystemMessage;
