import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
const REACT_APP_NODE_API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;

const SuggestedElement = () => {
    const [subjects, setsubjects] = useState([]);
    const [prompts, setprompts] = useState([]);
    const [elementMenu, setelementMenu] = useState([]);
    const [selectedStandard, setselectedStandard] = useState("");
    const [selectedSubject, setselectedSubject] = useState("");
    const [selectedMedium, setselectedMedium] = useState("");
    const [selectedPrompt, setselectedPrompt] = useState("");
    const [elementTitle, setelementTitle] = useState("");
    const [elementDescription, setelementDescription] = useState("");
    const [selectedElement, setselectedElement] = useState("");
    const [suggestedElement, setsuggestedElement] = useState([]);

    useEffect(() => {
        fetchSuggestedElement();
    }, []);

    const handleMedium = async (value) => {
        await FetchSubjects(selectedStandard, value);
        setselectedMedium(value);
    };

    const handleSubject = async (value) => {
        await fetchSubjectElement(value);
        setselectedSubject(value);
    }

    const handleElement = async (value) => {
        setselectedElement(value);
    }

    const handlePrompt = async (value) => {
        setselectedPrompt(value);
    }

    // Subject List
    const FetchSubjects = async (standard, medium) => {
        setsubjects([]);
        setselectedSubject("");
        try {
            const response = await axios.get(
                `${REACT_APP_NODE_API_BASE_URL}/trending/subjects`,
                {
                    params: {
                        standard_id: standard,
                        medium_id: medium,
                    },
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.data) {
                setsubjects(response.data);
            }
        } catch (error) {
            console.error("Error fetching subjects:", error);
        }
    };

    const fetchPrompts = async (subject) => {
        setprompts([])
        setselectedPrompt("");
        try {
            const response = await axios.get(
                `${REACT_APP_NODE_API_BASE_URL}/chapter-ai/v1/system-message?type=2`,
            );
            if (response.data) {
                setprompts(response.data.systemMessages);
            }
        } catch (error) {
            console.error("Error fetching prompts:", error);
        }
    }

    const fetchSubjectElement = async (value) => {
        setelementMenu([])
        setselectedElement("");
        try {
            const response = await axios.get(
                `${REACT_APP_NODE_API_BASE_URL}/chapter-ai/v1/element-menu?subject_id=${value}`,
            );
            if (response.data) {
                setelementMenu(response.data);
                fetchPrompts();
            }
        } catch (error) {
            console.error("Error fetching subject element:", error);
        }
    }

    const fetchSuggestedElement = async () => {
        try {
            const response = await axios.get(`${REACT_APP_NODE_API_BASE_URL}/chapter-ai/v1/suggested-task`);
            if (response.data) {
                setsuggestedElement(response.data);
            }
        } catch (error) {
            console.error("Error fetching suggested element:", error);
        }
    }

    const addSuggestedElement = async () => {
        if (!selectedStandard || !selectedSubject || !selectedMedium || !selectedElement || !elementTitle) {
            toast.error("All fields are mandatory", {
                position: "top-center",
            });
            return;
        }

        try {
            const addSuggestedElementData = {
                element_id: selectedElement,
                suggested_task_title: elementTitle,
                suggested_task_description: elementDescription,
                prompt_message_id: selectedPrompt,
            };
            const addElementResponse = await axios.post(
                `${REACT_APP_NODE_API_BASE_URL}/chapter-ai/v1/add/suggested-task`,
                addSuggestedElementData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            toast.success(addElementResponse.data.message, {
                position: "top-center",
            });
            // setselectedSubject("");
            // setselectedStandard("");
            // setselectedMedium("");
            // setselectedPrompt("");
            // setselectedElement("");
            setelementTitle("");
            setelementDescription("");
            fetchSuggestedElement();
        } catch (error) {
            console.error("Error adding chapter:", error);
        }
    };

    const deleteSuggestedElement = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this suggested element?");
        if (!confirmDelete) {
            return;
        }
        try {
            const deleteSuggestedElementResponse = await axios.delete(
                `${REACT_APP_NODE_API_BASE_URL}/chapter-ai/v1/suggested-task/${id}`,
            );
            toast.success(deleteSuggestedElementResponse.data.message, {
                position: "top-center",
            });
            fetchSuggestedElement();
        } catch (error) {
            console.error("Error deleting suggested element:", error);
        }
    }
    

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Upload Suggested task</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="">Home</a>
                            </li>
                            <li className="breadcrumb-item">Suggested task</li>
                        </ol>
                    </nav>
                </div>

                <div className="col-lg">
                    <div className="card">
                        <div className="card-body">
                            <form className="row g-3" encType="multipart/form-data">

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Standard:</label>
                                    <select
                                        className="form-control mt-1"
                                        value={selectedStandard}
                                        onChange={(e) => setselectedStandard(e.target.value)}
                                    >
                                        <option selected value="">
                                            Select Standard
                                        </option>
                                        <option value="1">ધોરણ 12</option>
                                        <option value="2">ધોરણ 11</option>
                                        <option value="3">ધોરણ 10</option>
                                        <option value="4">ધોરણ 9</option>
                                        <option value="5">ધોરણ 8</option>
                                        <option value="6">ધોરણ 7</option>
                                        <option value="7">ધોરણ 6</option>
                                        <option value="8">ધોરણ 5</option>
                                        <option value="9">ધોરણ 4</option>
                                        <option value="10">ધોરણ 3</option>
                                        <option value="11">ધોરણ 2</option>
                                        <option value="12">ધોરણ 1</option>
                                    </select>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Medium :</label>
                                    <select
                                        className="form-control mt-1"
                                        value={selectedMedium}
                                        onChange={(e) => handleMedium(e.target.value)}
                                    >
                                        <option selected value="">
                                            Select Medium
                                        </option>
                                        <option value="1">Gujarati Medium</option>
                                        <option value="2">English Medium</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Subjects:</label>

                                    <select
                                        className="form-control mt-1"
                                        value={selectedSubject}
                                        onChange={(e) => handleSubject(e.target.value)}
                                    >
                                        <option selected>Select subject</option>
                                        {subjects.map((subject, index) => (
                                            <option key={index} value={subject.id}>
                                                {subject.title}
                                            </option>

                                        ))}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Element:</label>

                                    <select
                                        className="form-control mt-1"
                                        value={selectedElement}
                                        onChange={(e) => handleElement(e.target.value)}
                                    >
                                        <option selected>Select element</option>
                                        {elementMenu.map((element, index) => (
                                            <option key={index} value={element.id}>
                                                {element.element_title}
                                            </option>

                                        ))}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Suggested task title:</label>
                                    <input type="text" className="form-control mt-1" value={elementTitle} onChange={(e) => setelementTitle(e.target.value)} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Suggested task description:</label>
                                    <textarea className="form-control mt-1" value={elementDescription} onChange={(e) => setelementDescription(e.target.value)} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">System Prompt:</label>

                                    <select
                                        className="form-control mt-1"
                                        value={selectedPrompt}
                                        onChange={(e) => handlePrompt(e.target.value)}
                                    >
                                        <option selected>Select prompt</option>
                                        {prompts.map((prompts, index) => (
                                            <option key={index} value={prompts.system_prompt_id}>
                                                {prompts.system_prompt_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>


                                <div className="text-center">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        name="insert"
                                        onClick={addSuggestedElement}
                                    >
                                        Upload Suggested Element
                                    </button>
                                </div>
                            </form>
                            <hr className="my-4"></hr>
                            <div className="scrolltable" style={{ overflow: "auto" }}>
                                <table className="table table-bordered" id="table-scroll">
                                    <thead>
                                        <tr>
                                            <th scope="col">Suggested Element ID</th>
                                            <th scope="col">Element ID</th>
                                            <th scope="col">Suggested Task</th>
                                            <th scope="col">Suggested Description</th>
                                            <th scope="col">Prompt message</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {suggestedElement.map((item, i) => (
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>{item.element_id}</td>
                                                <td>{item.suggested_task_title}</td>
                                                <td width={100} height={100}>
                                                    {item.suggested_task_description?.split(' ').slice(0, 10).join(' ')}
                                                    {item.suggested_task_description?.split(' ').length > 10 ? '...' : ''}
                                                </td>
                                                <td>{item.prompt_message_id ? item.prompt_message_id : "N/A"}</td>
                                                <td>
                                                    <Link to={`/edit-suggested-task/${item.id}`} className="btn btn-primary">Edit</Link>
                                                </td>
                                                <td>
                                                    <button className="btn btn-danger" onClick={() => deleteSuggestedElement(item.id)}>Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Vertical Form */}
                        </div>
                    </div>
                </div>
            </main>
            {/* End #main */}
        </>
    );
};

export default SuggestedElement;
