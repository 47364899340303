import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
const REACT_APP_NODE_API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;

const ManageSystemMessage = () => {
    const [subjects, setsubjects] = useState([]);
    const [prompts, setprompts] = useState([]);
    const [elementMenu, setelementMenu] = useState([]);
    const [selectedStandard, setselectedStandard] = useState("");
    const [selectedSubject, setselectedSubject] = useState("");
    const [selectedMedium, setselectedMedium] = useState("");
    const [selectedElementMenu, setselectedElementMenu] = useState("");
    const [elementTitle, setelementTitle] = useState("");
    const [elementImage, setelementImage] = useState("");
    const [elementType, setelementType] = useState("");
    const [isVisible, setisVisible] = useState(1);
    const [promptMessage, setpromptMessage] = useState("");

    const handleMedium = async (value) => {
        await FetchSubjects(selectedStandard, value);
        setselectedMedium(value);
    };

    const handleSubject = async (value) => {
        setselectedSubject(value);
        fetchSubjectElement(value);
    };

    const handleElementMenu = async (value) => {
        setselectedElementMenu(value);
    }

    // Subject List
    const FetchSubjects = async (standard, medium) => {
        try {
            const response = await axios.get(
                `${REACT_APP_NODE_API_BASE_URL}/trending/subjects`,
                {
                    params: {
                        standard_id: standard,
                        medium_id: medium,
                    },
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.data) {
                setsubjects(response.data);
            }
        } catch (error) {
            console.error("Error fetching subjects:", error);
        }
    };

    const fetchSubjectElement = async (subject) => {
        setelementMenu([])
        try {
            const response = await axios.get(
                `${REACT_APP_NODE_API_BASE_URL}/chapter-ai/v1/element-menu?subject_id=${subject}`,
            );
            if (response.data) {
                setelementMenu(response.data);
            }
        } catch (error) {
            console.error("Error fetching subject element:", error);
        }
    }

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Manage System Message</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="">Home</a>
                            </li>
                            <li className="breadcrumb-item">System Message</li>
                        </ol>
                    </nav>
                </div>

                <div className="col-lg">
                    <div className="card">
                        <div className="card-body">
                            <form className="row g-3" encType="multipart/form-data">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Standard:</label>
                                    <select
                                        className="form-control mt-1"
                                        value={selectedStandard}
                                        onChange={(e) => setselectedStandard(e.target.value)}
                                    >
                                        <option selected value="">
                                            Select Standard
                                        </option>
                                        <option value="1">ધોરણ 12</option>
                                        <option value="2">ધોરણ 11</option>
                                        <option value="3">ધોરણ 10</option>
                                        <option value="4">ધોરણ 9</option>
                                        <option value="5">ધોરણ 8</option>
                                        <option value="6">ધોરણ 7</option>
                                        <option value="7">ધોરણ 6</option>
                                        <option value="8">ધોરણ 5</option>
                                        <option value="9">ધોરણ 4</option>
                                        <option value="10">ધોરણ 3</option>
                                        <option value="11">ધોરણ 2</option>
                                        <option value="12">ધોરણ 1</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Medium :</label>
                                    <select
                                        className="form-control mt-1"
                                        value={selectedMedium}
                                        onChange={(e) => handleMedium(e.target.value)}
                                    >
                                        <option selected value="">
                                            Select Medium
                                        </option>
                                        <option value="1">Gujarati Medium</option>
                                        <option value="2">English Medium</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Subjects:</label>

                                    <select
                                        className="form-control mt-1"
                                        value={selectedSubject}
                                        onChange={(e) => handleSubject(e.target.value)}
                                    >
                                        <option selected>Select subject</option>
                                        {subjects.map((subject, index) => (
                                            <option key={index} value={subject.id}>
                                                {subject.title}
                                            </option>

                                        ))}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Element Menu:</label>

                                    <select
                                        className="form-control mt-1"
                                        value={selectedElementMenu}
                                        onChange={(e) => handleElementMenu(e.target.value)}
                                    >
                                        <option selected>Select element menu</option>
                                        {elementMenu.map((element, index) => (
                                            <option key={element.id} value={element.id}>
                                                {element.element_title}
                                            </option>

                                        ))}
                                    </select>
                                </div>

                            </form>
                            <hr className="my-4"></hr>
                            <div className="scrolltable" style={{ overflow: "auto" }}>
                                <table className="table table-bordered" id="table-scroll">
                                    <thead>
                                        <tr>
                                            <th scope="col">Element ID</th>
                                            <th scope="col">Element title</th>
                                            <th scope="col">Element image</th>
                                            <th scope="col">Element type</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {elementMenu.map((item, i) => (
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>{item.element_title}</td>
                                                <td><img src={`${item.icon_path}`} alt="Element image" width={50} height={50} /></td>
                                                <td>{item.type}</td>
                                                <td>
                                                    <Link to={`/edit-system-message/${item.id}`} className="btn btn-primary">Edit</Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Vertical Form */}
                        </div>
                    </div>
                </div>
            </main>
            {/* End #main */}
        </>
    );
};

export default ManageSystemMessage;
