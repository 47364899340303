import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
const REACT_APP_NODE_API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;

const SpecialExam = () => {
  const [selectedSubject, setSelectedSubject] = useState();
  const [subjects, setsubjects] = useState([]);
  const [Content, setContent] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [ExamID, setExamID] = useState(null);
  const [Date, setDate] = useState("");

  useEffect(() => {
    if (selectedType == 2) {
      FetchSubjects(ExamID);
    }
  }, [selectedType, ExamID]);

  const handleSubject = async (subjectID) => {
    setSelectedSubject(subjectID);
  };

  // Subject List
  const FetchSubjects = async (value) => {
    try {
      const response = await axios.get(
        `${REACT_APP_NODE_API_BASE_URL}/special-exam/subjects/${ExamID}?type=1`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        setsubjects(response.data[0].subjects);
      }
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const convertTypeToNumber = (type) => {
    switch (type) {
      case "t":
        return 1;
      case "e":
        return 2;
      case "m":
        return 3;
      case "v":
        return 4;
      default:
        return -1; // Invalid type
    }
  };

  const insertMocktests = async () => {
    if (!ExamID || !Content || !selectedType?.length) {
      toast.error("કૃપા કરીને મારા સાહેબ બધું ભરો ને.", {
        position: "top-center",
      });
      return;
    }

    const regex = /content_id\/([temv])=(.*?)$/;
    const match = Content.match(regex);

    if (match && match.length > 2) {
      const type = match[1];
      const base64String = match[2];
      const decodedString = atob(base64String);
      const id = decodedString;

      const numericType = convertTypeToNumber(type);
      console.log(numericType);

           if (numericType === 1 || numericType === 2) {
        try {
          const addMockTestsPayload = {
            special_exam_id: ExamID,
            subject_id: selectedSubject,
            is_subjectWise: selectedSubject ? true : false,
            content_type: numericType,
            content_id: id,
            schedule_time: Date ? Date : null,
          };

          const addMockTestsReponse = await axios.post(
            `${REACT_APP_NODE_API_BASE_URL}/special-exam/add-mocktest`,
            addMockTestsPayload,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (addMockTestsReponse.data) {
            toast.success(addMockTestsReponse.data.message, {
              position: "top-center",
            });
            setContent("");
            selectedType("");
          }
        } catch (error) {
          console.error("Error adding mocktests:", error);
        }
      } else {
        toast.error("અત્યારે તો ક્વિઝ સુધી જ છે, મારા વાલા", {
          position: "top-center",
        });
      }
    } else {
      toast.error("Invalid URL", {
        position: "top-center",
      });
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Upload Mock Tests</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li className="breadcrumb-item">Upload MockTest</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body">
              <form className="row g-3" encType="multipart/form-data">
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Exam:</label>

                  <select
                    className="form-select"
                    value={ExamID}
                    onChange={(e) => setExamID(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="1">NMMS</option>
                    <option value="2">Gyan Sadhana</option>
                    <option value="3">PSE</option>
                    <option value="4">CET</option>
                    <option value="5">Navoday</option>
                    <option value="6">NEET</option>
                    <option value="7">JEE</option>
                    <option value="8">ધોરણ ૧૦ પરીક્ષા</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Type:</label>
                  <select
                    className="form-control mt-1"
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                  >
                    <option selected value="">
                      Select Type
                    </option>
                    <option value="1">ધોરણ પ્રમાણે</option>
                    <option value="2">વિષય પ્રમાણે</option>
                  </select>
                </div>

                {selectedType === "2" && (
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">Subjects:</label>

                    <select
                      className="form-control mt-1"
                      value={selectedSubject}
                      onChange={(e) => handleSubject(e.target.value)}
                    >
                      <option selected>Select subject</option>
                      {subjects.map((subject, index) => (
                        <option key={index} value={subject.subject_id}>
                          {subject.subject_name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter MockTest Link:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="https://edutorapp.com/content_id/m=MjUzNTU="
                    value={Content}
                    onChange={(e) => setContent(e.target.value)}
                    required
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Schedual Time{" "}
                    <span className="text-danger">
                      (શેડ્યૂલ કરવું હોય તો જ માહિતી ભરો.)
                    </span>
                  </label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={Date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>

                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    name="insert"
                    onClick={insertMocktests}
                  >
                    Add Mock Test 🎉
                  </button>
                </div>
              </form>
              {/* Vertical Form */}
            </div>
          </div>
        </div>
      </main>
      {/* End #main */}
    </>
  );
};

export default SpecialExam;
