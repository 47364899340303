import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
const REACT_APP_NODE_API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;

const UpdateSuggestedTask = () => {
    document.title = "Edit Suggested Task | Edutor App";
    const { element_id } = useParams();
    const [prompts, setprompts] = useState([]);
    const [selectedPrompt, setselectedPrompt] = useState("");
    const [suggestedTaskTitle, setSuggestedTaskTitle] = useState(null);
    const [suggestedTaskDescription, setSuggestedTaskDescription] = useState(null);
    const [elementMenu, setelementMenu] = useState([]);
    const [selectedElement, setselectedElement] = useState("");

    const updateSystemMessage = async () => {
        if (!suggestedTaskTitle) {
            toast.error("Suggested Task Title is mandatory", {
                position: "top-center",
            });
            return;
        }
        try {
            const updateSuggestedTask = {
                element_id: selectedElement,
                suggested_task_title: suggestedTaskTitle,
                suggested_task_description: suggestedTaskDescription,
                prompt_message_id: selectedPrompt
            };

            const updateSystemMessageResponse = await axios.put(
                `${REACT_APP_NODE_API_BASE_URL}/chapter-ai/v1/suggested-task/${element_id}`,
                updateSuggestedTask,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (updateSystemMessageResponse.data) {
                toast.success(updateSystemMessageResponse.data.message, {
                    position: "top-center",
                });
            }
        } catch (error) {
            toast.error(error.response.data.error, {
                position: "top-center",
            });
            console.error("Error updating suggested task:", error);
        }
    };

    const fetchPrompts = async (subject) => {
        try {
            const response = await axios.get(
                `${REACT_APP_NODE_API_BASE_URL}/chapter-ai/v1/system-message?type=2`,
            );
            if (response.data) {
                setprompts(response.data.systemMessages);
            }
        } catch (error) {
            console.error("Error fetching prompts:", error);
        }
    }

    const fetchElement = async () => {
        const response = await axios.get(`${REACT_APP_NODE_API_BASE_URL}/chapter-ai/v1/element-menu`);
        setelementMenu(response.data);
    }
    const fetchSuggestedTask = async () => {
        const response = await axios.get(`${REACT_APP_NODE_API_BASE_URL}/chapter-ai/v1/suggested-task/${element_id}`);
        setSuggestedTaskTitle(response.data.suggested_task_title);
        setSuggestedTaskDescription(response.data.suggested_task_description);
        setselectedElement(response.data.element_id);
        setselectedPrompt(response.data.prompt_message_id);
    };

    useEffect(() => {
        fetchSuggestedTask();
        fetchElement();
        fetchPrompts();
    }, []);

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Edit Suggested Task</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/dashboard">Home</a>
                            </li>
                            <li className="breadcrumb-item">Edit Suggested Task</li>
                        </ol>
                    </nav>
                </div>

                <div className="col-lg">
                    <div className="card">
                        <div className="card-body">
                            <form className="row g-3" encType="multipart/form-data">
                                
                            <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Element:</label>

                                    <select
                                        className="form-control mt-1"
                                        value={selectedElement}
                                        onChange={(e) => setselectedElement(e.target.value)}
                                    >
                                        <option selected>Select element</option>
                                        {elementMenu.map((element, index) => (
                                            <option key={index} value={element.id}>
                                                {element.element_title}
                                            </option>

                                        ))}
                                    </select>
                                </div>

                            <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Suggested task title:</label>
                                    <input type="text" className="form-control mt-1" value={suggestedTaskTitle} onChange={(e) => setSuggestedTaskTitle(e.target.value)} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Suggested task description:</label>
                                    <textarea className="form-control mt-1" value={suggestedTaskDescription} onChange={(e) => setSuggestedTaskDescription(e.target.value)} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">System Prompt:</label>

                                    <select
                                        className="form-control mt-1"
                                        value={selectedPrompt}
                                        onChange={(e) => setselectedPrompt(e.target.value)}
                                    >
                                        <option selected>Select prompt</option>
                                        {prompts.map((prompts, index) => (
                                            <option key={index} value={prompts.system_prompt_id}>
                                                {prompts.system_prompt_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="text-center">
                                    <button
                                        type="button"
                                        className="btn btn-primary mx-3"
                                        name="insert"
                                        onClick={updateSystemMessage}
                                    >
                                        Update Suggested Task
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default UpdateSuggestedTask;
