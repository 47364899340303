import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_NODE_API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;




const ChaptersMainPage = () => {
  const navigate = useNavigate();
  const [selectedStandard, setselectedStandard] = useState("");
  const [selectedSubject, setselectedSubject] = useState("");
  const [subjects, setsubjects] = useState([]);
  const [ChaptersName, setChaptersName] = useState([]);
  const [ChaptersData, setChaptersData] = useState([]);
  const [LoadAgain, setLoadAgain] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMedium, setselectedMedium] = useState("");

  // Assuming ChaptersData is passed as a prop or imported
  const filteredChapters = ChaptersData.filter((item) =>
    searchTerm === "" ? true : item.subject_id.toString() === searchTerm
  );

  useEffect(() => {
    FetchChaptersData();
  }, [LoadAgain]);

  const handleStandard = async (value) => {
    await FetchSubjects(value, selectedMedium);
    setselectedStandard(value);
  };

  // Subject List
  const FetchSubjects = async (standard_id, medium_id) => {
    try {
      const response = await axios.get(
        `${REACT_APP_NODE_API_BASE_URL}/trending/subjects?`,
        {
          params: {
            standard_id,
            medium_id,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        setsubjects(response.data);
      }
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  // Fetch Chapter Data
  const FetchChaptersData = async (value) => {
    try {
      const response = await axios.get(
        `${REACT_APP_NODE_API_BASE_URL}/chapter`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        setChaptersData(response.data);
      }
    } catch (error) {
      console.error("Error fetching chapter:", error);
    }
  };

  const addChapter = async () => {
    setLoadAgain(false);
    if (!selectedStandard || !selectedSubject || !ChaptersName || !selectedMedium) {
      toast.error("All fields are mandatory", {
        position: "top-center",
      });
      return;
    }

    try {
      const newChapterData = {
        chapter_name: ChaptersName,
        subject_id: parseInt(selectedSubject),
      };

      const addChapterResponse = await axios.post(
        `${REACT_APP_NODE_API_BASE_URL}/admin/add/chapter`,
        newChapterData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (addChapterResponse.data) {
        toast.success(addChapterResponse.data.message, {
          position: "top-center",
        });
        setLoadAgain(true);
        // setselectedSubject("");
        // setselectedStandard("");
        setChaptersName("");
      }
    } catch (error) {
      console.error("Error adding chapter:", error);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Upload Chapters</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li className="breadcrumb-item">Upload Chapters </li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body">
              <form className="row g-3" encType="multipart/form-data">

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Medium :</label>
                  <select
                    className="form-control mt-1"
                    value={selectedMedium}
                    onChange={(e) => setselectedMedium(e.target.value)}
                  >
                    <option selected value="">
                      Select Medium
                    </option>
                    <option value="1">Gujarati Medium</option>
                    <option value="2">English Medium</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Standard:</label>
                  <select
                    className="form-control mt-1"
                    value={selectedStandard}
                    onChange={(e) => handleStandard(e.target.value)}
                  >
                    <option selected value="">
                      Select Standard
                    </option>
                    <option value="1">ધોરણ 12</option>
                    <option value="2">ધોરણ 11</option>
                    <option value="3">ધોરણ 10</option>
                    <option value="4">ધોરણ 9</option>
                    <option value="5">ધોરણ 8</option>
                    <option value="6">ધોરણ 7</option>
                    <option value="7">ધોરણ 6</option>
                    <option value="8">ધોરણ 5</option>
                    <option value="9">ધોરણ 4</option>
                    <option value="10">ધોરણ 3</option>
                    <option value="11">ધોરણ 2</option>
                    <option value="12">ધોરણ 1</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Subjects:</label>

                  <select
                    className="form-control mt-1"
                    value={selectedSubject}
                    onChange={(e) => setselectedSubject(e.target.value)}
                  >
                    <option selected>Select subject</option>
                    {subjects.map((subject, index) => (
                      <option key={index} value={subject.id}>
                        {subject.title}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Chapters Tittle:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={ChaptersName}
                    onChange={(e) => setChaptersName(e.target.value)}
                    required
                  />
                </div>

                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    name="insert"
                    onClick={addChapter}
                  >
                    Add Chapters
                  </button>
                </div>
              </form>
              {/* Vertical Form */}

              <b>
                <h4>Lasted Added Chapter List</h4>
              </b>
              <div>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Subject ID"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">🔍</span>
                  </div>
                </div>
                <div className="scrolltable" style={{ overflow: "auto" }}>
                  <table className="table table-bordered" id="table-scroll">
                    <thead>
                      <tr>
                        <th scope="col">Chapter ID</th>
                        <th scope="col">Subject ID</th>
                        <th scope="col">Subject Name</th>
                        <th scope="col">Chapter Title</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredChapters.map((item, i) => (
                        <tr key={item.chapter_id}>
                          <td>{item.chapter_id}</td>
                          <td>{item.subject_id}</td>
                          <td>{item.subject_title}</td>
                          <td width={500}>{item.chapter_name}</td>
                          <td>
                            <Link
                              to={`/edit-chapter/${item.chapter_id}/${item.subject_id}`}
                            >
                              Edit
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* End #main */}
    </>
  );
};

export default ChaptersMainPage;
