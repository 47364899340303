import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
const REACT_APP_NODE_API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;

const UpdateSystemMessage = () => {
    document.title = "Edit System Message | Edutor App";
    const { element_id } = useParams();
    const [editElement, setEditElement] = useState(false);
    const [systemPrompt, setSystemPrompt] = useState(null);
    const [promptMessage, setPromptMessage] = useState(null);
    const [systemPromptName, setSystemPromptName] = useState(null);

    const updateSystemMessage = async () => {
        if (!systemPromptName) {
            toast.error("System Prompt Name is mandatory", {
                position: "top-center",
            });
            return;
        }
        try {
            const updateSystemMessage = {
                prompt_message: promptMessage,
                system_prompt_name: systemPromptName
            };

            const updateSystemMessageResponse = await axios.put(
                `${REACT_APP_NODE_API_BASE_URL}/chapter-ai/v1/update/system-message/${element_id}`,
                updateSystemMessage,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (updateSystemMessageResponse.data) {
                toast.success(updateSystemMessageResponse.data.message, {
                    position: "top-center",
                });
                setEditElement(false);
            }
        } catch (error) {
            toast.error(error.response.data.error, {
                position: "top-center",
            });
            console.error("Error updating system message:", error);
        }
    };

    const enableEdit = () => {
        setEditElement(true);
    };

    const fetchSystemMessage = async () => {
        const response = await axios.get(`${REACT_APP_NODE_API_BASE_URL}/chapter-ai/v1/system-message?system_prompt_id=${element_id}`);
        setSystemPrompt(response.data.systemMessages.system_message);
        setPromptMessage(response.data.systemMessages.prompt_message);
        setSystemPromptName(response.data.systemMessages.system_prompt_name);
    };

    useEffect(() => {
        fetchSystemMessage();
    }, []);

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Edit System Message</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/dashboard">Home</a>
                            </li>
                            <li className="breadcrumb-item">Edit System Message</li>
                        </ol>
                    </nav>
                </div>

                <div className="col-lg">
                    <div className="card">
                        <div className="card-body">
                            <form className="row g-3" encType="multipart/form-data">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">System Prompt Name: </label>
                                    <input
                                        className="form-control"
                                        value={systemPromptName}
                                        onChange={(e) => setSystemPromptName(e.target.value)}
                                        required
                                        rows={1}
                                        disabled={!editElement}
                                    />
                                </div>

                                {/* <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">System Message: </label>
                                    <textarea
                                        className="form-control"
                                        value={systemPrompt}
                                        onChange={(e) => setSystemPrompt(e.target.value)}
                                        required
                                        rows={9}
                                        disabled={!editElement}
                                    />
                                </div> */}

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Prompt Message: </label>
                                    <textarea
                                        className="form-control"
                                        value={promptMessage}
                                        onChange={(e) => setPromptMessage(e.target.value)}
                                        required
                                        rows={9}
                                        disabled={!editElement}
                                    />
                                </div>
                                <div className="text-center">
                                    <button
                                        type="button"
                                        className="btn btn-success mx-3"
                                        name="insert"
                                        onClick={enableEdit}
                                    >
                                        Edit Message
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary mx-3"
                                        name="insert"
                                        onClick={updateSystemMessage}
                                    >
                                        Update Message
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default UpdateSystemMessage;
