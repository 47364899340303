import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
const REACT_APP_NODE_API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;

const EduMitraPage = () => {
  const navigate = useNavigate();
  const [selectedStandard, setselectedStandard] = useState("");
  const [selectedSubject, setSelectedSubject] = useState();
  const [subjects, setsubjects] = useState([]);
  const [ChaptersName, setChaptersName] = useState([]);
  const [selectedChapter, setselectedChapter] = useState("");
  const [ChaptersData, setChaptersData] = useState([]);
  const [TopicName, setTopicName] = useState("");
  const [TopicData, setTopicData] = useState([]);
  const [LoadAgain, setLoadAgain] = useState(false);
  const [showAllData, setShowAllData] = useState(false);
  const [ascendingOrder, setAscendingOrder] = useState(false);

  useEffect(() => {
    FetchTopicData();
  }, [LoadAgain, showAllData]);

  const handleStandard = async (value) => {
    await FetchSubjects(value);
    setselectedStandard(value);
  };

  const handleSubject = async (subjectID) => {
    await FetchChaptersData(subjectID);
    setSelectedSubject(subjectID);
  };

  // Subject List
  const FetchSubjects = async (value) => {
    try {
      const response = await axios.get(
        `${REACT_APP_NODE_API_BASE_URL}/trending/subjects`,
        {
          params: {
            standard_id: value,
            medium_id: 1,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        setsubjects(response.data);
      }
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  // Fetch Chapter Data
  const FetchChaptersData = async (value) => {
    try {
      const response = await axios.get(
        `${REACT_APP_NODE_API_BASE_URL}/chapter?subject_id=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        setChaptersData(response.data);
      }
    } catch (error) {
      console.error("Error fetching chapter:", error);
    }
  };

  // Fetch Topic Data
  const FetchTopicData = async (value) => {
    try {
      let limitValue = showAllData ? 99999 : 10; // Set limit based on showAllData state
      const response = await axios.get(
        `${REACT_APP_NODE_API_BASE_URL}/edu-mitra?limit=${limitValue}&type=1`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        setTopicData(response.data);
      }
    } catch (error) {
      console.error("Error fetching chapter:", error);
    }
  };

  const toggleSortingOrder = () => {
    setAscendingOrder(!ascendingOrder);
  };

  const AddTopic = async () => {
    setLoadAgain(false);
    if (!selectedStandard?.length || !selectedSubject?.length || !TopicName) {
      toast.error("All fields are mandatory", {
        position: "top-center",
      });
      return;
    }

    try {
      const newTopicData = {
        board_id: 1,
        medium_id: 1,
        standard_id: parseInt(selectedStandard),
        subject_id: parseInt(selectedSubject),
        chapter_id: parseInt(selectedChapter),
        tittle: TopicName,
      };

      const addTopicResponse = await axios.post(
        `${REACT_APP_NODE_API_BASE_URL}/admin/add/edu-mitra`,
        newTopicData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (addTopicResponse.data) {
        toast.success(addTopicResponse.data.message, {
          position: "top-center",
        });
        setLoadAgain(true);
        setTopicName("");
        // setselectedSubject("");
        // setselectedStandard("");
        // setChaptersName("");
      }
    } catch (error) {
      console.error("Error adding topic:", error);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Upload EduMitra</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li className="breadcrumb-item">Upload EduMitra</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body">
              <form className="row g-3" encType="multipart/form-data">
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Standard:</label>
                  <select
                    className="form-control mt-1"
                    value={selectedStandard}
                    onChange={(e) => handleStandard(e.target.value)}
                  >
                    <option selected value="">
                      Select Standard
                    </option>
                    <option value="1">ધોરણ 12</option>
                    <option value="2">ધોરણ 11</option>
                    <option value="3">ધોરણ 10</option>
                    <option value="4">ધોરણ 9</option>
                    <option value="5">ધોરણ 8</option>
                    <option value="6">ધોરણ 7</option>
                    <option value="7">ધોરણ 6</option>
                    <option value="8">ધોરણ 5</option>
                    <option value="9">ધોરણ 4</option>
                    <option value="10">ધોરણ 3</option>
                    <option value="11">ધોરણ 2</option>
                    <option value="12">ધોરણ 1</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Subjects:</label>

                  <select
                    className="form-control mt-1"
                    value={selectedSubject}
                    onChange={(e) =>
                      handleSubject(
                        e.target.value,
                        e.target.options[e.target.selectedIndex].text
                      )
                    }
                  >
                    <option selected>Select subject</option>
                    {subjects.map((subject, index) => (
                      <option key={index} value={subject.id}>
                        {subject.title}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Chapter:</label>

                  <select
                    className="form-control mt-1"
                    value={selectedChapter}
                    onChange={(e) => setselectedChapter(e.target.value)}
                  >
                    <option selected>Select Chapter</option>
                    {ChaptersData.map((chapter, index) => (
                      <option key={index} value={chapter.chapter_id}>
                        {chapter.chapter_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter EduMitra Tittle:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={TopicName}
                    onChange={(e) => setTopicName(e.target.value)}
                    required
                  />
                </div>

                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-info"
                    name="insert"
                    onClick={AddTopic}
                  >
                    Add EduMitra
                  </button>
                </div>
              </form>
              {/* Vertical Form */}

              <b>
                <h4 className="mt-3">
                  <b>Lasted</b>
                </h4>
              </b>
              <div className="scrolltable" style={{ overflow: "auto" }}>
                <table className="table table-bordered" id="table-scroll">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">ID</th>
                      {/* <th scope="col">Standard</th> */}
                      <th onClick={toggleSortingOrder} style={{ cursor: "pointer" }}>
                        Standard
                        {ascendingOrder ? " ▲" : null}
                      </th>
                      <th scope="col">Subject</th>
                      <th scope="col">Chapter Name</th>
                      <th scope="col">Topic Tittle</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  {TopicData.sort((a, b) =>
                    ascendingOrder
                      ? b.standard_id - a.standard_id
                      : null
                  ).map((item, i) => {
                    return (
                      <tbody>
                        <tr key={item}>
                          <th>{i + 1}</th>
                          <th>edumitra_{item.eduMitra_id}</th>
                          <th>{item.standard_id}</th>
                          <th>{item.subject_title}</th>
                          <th
                            width={500}
                            style={{
                              color: item.chapter_name ? "black" : "red",
                            }}
                          >
                            {item.chapter_name || "NAN"}
                          </th>
                          <th width={300}>{item.tittle}</th>
                          <td>
                            <Link
                              to={`/edit-edumitra/${item.eduMitra_id}`}
                              className="btn btn-primary"
                            >
                              Edit
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>

                <button
                  type="button"
                  className="btn btn-info mt-3"
                  onClick={() => setShowAllData(!showAllData)}
                >
                  {showAllData ? "Show Less" : "Show All"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* End #main */}
    </>
  );
};

export default EduMitraPage;
