import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { Link } from "react-router-dom";
const REACT_APP_NODE_API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;

const UpdateElement = () => {
    const { element_id } = useParams();
    const [prompts, setprompts] = useState([]);
    const [elementMenu, setelementMenu] = useState([]);
    const [selectedPrompt, setselectedPrompt] = useState("");
    const [elementTitle, setelementTitle] = useState("");
    const [elementImage, setelementImage] = useState("");
    const [elementType, setelementType] = useState("");
    const [isVisible, setisVisible] = useState(1);
    const [promptMessage, setpromptMessage] = useState("");

    useEffect(() => {
        fetchSubjectElement();
        fetchPrompts();
    }, []);

    const fetchPrompts = async (subject) => {
        try {
            const response = await axios.get(
                `${REACT_APP_NODE_API_BASE_URL}/chapter-ai/v1/system-message?type=1`,
            );
            if (response.data) {
                setprompts(response.data.systemMessages);
            }
        } catch (error) {
            console.error("Error fetching prompts:", error);
        }
    }

    const fetchSubjectElement = async () => {
        try {
            const response = await axios.get(
                `${REACT_APP_NODE_API_BASE_URL}/chapter-ai/element-menu/${element_id}`,
            );
            if (response.data) {
                setselectedPrompt(response.data.prompt_message_id);
                setelementTitle(response.data.element_title);
                setelementImage(response.data.icon_path);
                setelementType(response.data.type);
                setisVisible(response.data.is_visible);
            }
        } catch (error) {
            console.error("Error fetching subject element:", error);
        }
    }

    const addElement = async () => {
        if (!elementTitle || !elementImage || !elementType) {
            toast.error("All fields are mandatory", {
                position: "top-center",
            });
            return;
        }

        try {
            const addElementData = {
                element_title: elementTitle,
                icon_path: elementImage,
                type: elementType,
                is_visible: isVisible,
                prompt_message_id: selectedPrompt,
            };
            const addElementResponse = await axios.put(
                `${REACT_APP_NODE_API_BASE_URL}/chapter-ai/element-menu/${element_id}`,
                addElementData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            toast.success(addElementResponse.data.message, {
                position: "top-center",
            });
        } catch (error) {
            console.error("Error adding chapter:", error);
        }
    };

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Edit Element</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="">Home</a>
                            </li>
                            <li className="breadcrumb-item">Element</li>
                        </ol>
                    </nav>
                </div>

                <div className="col-lg">
                    <div className="card">
                        <div className="card-body">
                            <form className="row g-3" encType="multipart/form-data">

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Element title:</label>
                                    <input type="text" className="form-control mt-1" value={elementTitle} onChange={(e) => setelementTitle(e.target.value)} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Element Image:</label>
                                    <input type="text" className="form-control mt-1" value={elementImage} onChange={(e) => setelementImage(e.target.value)} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Element Type:</label>
                                    <input type="number" className="form-control mt-1" value={elementType} onChange={(e) => setelementType(e.target.value)} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Visible:</label>
                                    <select className="form-control mt-1" value={isVisible} onChange={(e) => setisVisible(e.target.value)}>
                                        <option value="">Select visibility</option>
                                        <option value="1">Show</option>
                                        <option value="0">Hide</option>
                                    </select>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Prompt:</label>
                                    <select
                                        className="form-control mt-1"
                                        value={selectedPrompt}
                                        onChange={(e) => setselectedPrompt(e.target.value)}
                                    >
                                        <option selected>Select prompt</option>
                                        {prompts.map((prompts, index) => (
                                            <option key={index} value={prompts.system_prompt_id}>
                                                {prompts.system_prompt_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>


                                <div className="text-center">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        name="insert"
                                        onClick={addElement}
                                    >
                                        Edit Element
                                    </button>
                                </div>
                            </form>
                            {/* Vertical Form */}
                        </div>
                    </div>
                </div>
            </main>
            {/* End #main */}
        </>
    );
};

export default UpdateElement;
